/* eslint-disable quotes */
import Head from 'next/head';
import Script from 'next/script';
import '@/styles/global.scss';
import '@@/styles/index.scss';

function App({ Component, pageProps }) {
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta name="robots" content="all" />
        <meta name="googlebot" content="all" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
        />
        <meta name="format-detection" content="telephone=no,email=no,adress=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <link rel="dns-prefetch" href="//assets.papegames.com" />
        <meta name="full-screen" content="yes" />
        <meta name="x5-fullscreen" content="true" />
        <meta name="x5-page-mode" content="app" />
        <meta name="360-fullscreen" content="true" />
        <meta name="renderer" content="webkit" />
      </Head>
      {process.env.APP_ENV === 'prod' && (
        <>
          <Script id="monitor" strategy="beforeInteractive">
            {`sessionStorage.CUSTOMER_WEB_MONITOR_ID="webfunny_20220809_145052_pro"`}
          </Script>
          <Script crossOrigin="anonymous" async src="//assets.papegames.com/libs/monitor.js" />
          <Script async src="https://hm.baidu.com/hm.js?3e06ca33df3cfd19b4ee957588562d11" />
        </>
      )}
      <Component {...pageProps} />
    </>
  );
}

export default App;
